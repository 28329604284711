.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    margin: auto;
    width: 100%;
    text-align: center;
    color: #fff;
    background-color: #00305C;
    line-height: 50px;
}