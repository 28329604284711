.App-logo {
    height: 50px;
    margin: 10px 30px;
}


.App-header {
    display: flex;
    align-items: center;
    height: 70px;
    width: 100vw;
    color: #fff;
    background-color: #00305C;
    box-shadow: rgb(220, 220, 220) 0px 2px 10px;
}

.title {
    font-size: 30px;
    font-weight: bold;
    margin: 0px;
    width: 200;
}